<template>
  <div style="overflow: hidden;">
    <div>
      <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
      ></NavBar>
    </div>
    <v-app>
      <v-row style="height: 96vh; overflow-y: scroll; background-color: #EFF4FF;">
        <v-col cols="2">
          <SideBar :pageName="pageName"></SideBar>
        </v-col>
        <v-col cols="10">
          <div v-if="!isLoading" align="left" style="padding-left: 64px; padding-top: 48px; display: flex; justify-content: space-between;">
            <div>
              <span style="font-weight: 800; font-size: 12pt; text-transform: uppercase; letter-spacing: 1px;">
                <h3>Coupon</h3>
              </span>
            </div>
            <div style="display: flex; justify-content: space-around; width: 25%; margin-right: 10%">
              <div style="padding: 0px 20px;">
                <v-text-field
                  v-model="search_coupon_availability"
                  outlined
                  solo
                  height="10%"
                  dense
                  placeholder="Enter coupon number"
                  style="width: 200px"
                  >
                </v-text-field>
              </div>
              <div style="padding: 0px 20px 0px 10px; margin-right: 20px;">
                <v-btn class = "primary" v-if = "search_coupon_availability" style="width:100px; padding: 5px; height: 40px;" @click="search_validity" :disabled = "search_disable"><span style="font-size: 18pt;"> <v-icon>mdi-magnify</v-icon></span></v-btn>
              </div>
            </div>
          </div>
          <div align="center" style="padding-left: 64px; padding-top: 48px;" v-if="isLoading">
            <div>
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
          </div>
          <v-row style="padding-top: 20px;" v-else>
              <v-col cols="11" style="padding-left: 64px;">
                <v-card flat style="padding: 12px 12px 0px 12px; line-height: 24px;">
                  <div style="display: flex; flex-direction: column;">
                    <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Coupon name:</span><span style="font-weight: 600; font-size: 8pt; line-height: 24px;">{{ coupon_name }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Discount value:</span><span class="coupon_details_value">{{ discounted_value }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Applicable to:</span><span class="coupon_details_value">{{ applicable_to }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Coupon Type:</span><span class="coupon_details_value">{{ coupon_type }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Max Discount amount:</span><span class="coupon_details_value">{{ max_discount_amount }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Total:</span> <span class="coupon_details_value">{{ total_published }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Start date:</span><span class="coupon_details_value">{{ coupon_start_date }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Minimum spend amount:</span><span class="coupon_details_value">{{ minimum_spend_amount }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Coupons Used:</span> <span class="coupon_details_value">{{ used_count }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="view_more">
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">End date:</span><span class="coupon_details_value">{{ coupon_end_date }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Discount Scope:</span><span class="coupon_details_value">{{ applicable_on }}</span>
                      </div>
                      <div align="left" class="coupon_desc_class">
                        <span class="coupon_details_title">Coupons Unused:</span> <span class="coupon_details_value">{{ unused_count }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" style="padding: 8px; width: 100%">
                        <span class="coupon_details_title">Description:</span> <span class="coupon_details_value">{{ coupon_desc }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" style="padding: 8px; width: 100%">
                        <span class="coupon_details_title">Discount Scope List:</span> <span class="coupon_details_value">{{ applicable_list.join(", ") }}</span>
                      </div>
                    </div>
                  </div>
                  <div align="center">
                    <div class="viewMoreclass" @click="toggleDetails">
                      <div><span class="textClass">{{ toggle_text }}</span></div>
                      <div>
                        <img :class="showContentClass" src = "https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/show_more.svg" />
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>
          </v-row>
          <v-row style="padding-top: 20px;">
            <v-col cols="11" style="padding-left: 64px;">
              <div align="center"  v-if="allUsedCoupons.length > 0 && !isLoading">
                <div class="fixTableHead">
                  <table class="tableClass" style="width: 100%;">
                    <tr align="center">
                      <th style="padding-left:2%">Coupon code</th>
                      <th>App. Number</th>
                      <th>Used on</th>
                      <th>User Name</th>
                      <th>Speciality</th>
                      <th>Doctor Name</th>
                    </tr>
                    <tr align="center" v-for="usedCoupon in allUsedCoupons" :key="usedCoupon._id">
                      <td style="padding-left:2%">{{ usedCoupon.coupon_code }}</td>
                      <td style="padding-left:2%">{{ usedCoupon.appointment_ref_no }}</td>
                      <td>{{ usedCoupon.availed_on.split("T")[0] }}</td>
                      <td>{{ usedCoupon.availed_by_name}} </td>
                      <td>{{ usedCoupon.availed_on_spec}} </td>
                      <td>{{ usedCoupon.availed_on_doc }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div v-else-if="!isLoading">
                No used coupons for this coupon family.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "ViewAllSuggestion",
  components: {
    NavBar,SideBar,
  },
  data() {
    return {
      pageName: "ParticularCouponFamily",
      currentUserName: "",
      currentUser: "",
      couponId: "",
      isLoading: false,
      coupon_name: "",
      coupon_start_date: "",
      coupon_end_date: "",
      total_published: null,
      used_count: null,
      unused_count: null,
      coupon_desc: "",
      search_type: [],
      search_value: [],
      selected_search_value: "",
      selected_search_type: "",
      allUsedCoupons: "",
      master_coupon_details: "",
      search_coupon_availability: null,
      windowHeight: window.innerHeight,
      txt: '',
      search_disable: false,
      coupon_type: null,
      max_discount_amount: null,
      minimum_spend_amount: null,
      applicable_on: null,
      applicable_to: null,
      discounted_value: null,
      view_more: false,
      toggle_text: "Show More",
      showContentClass: "ShowMoreClass",
      applicable_list: null,
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      this.isLoading = true;
      var get_admin_body = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType: "ADMIN"
      };
      this.coupon_id = this.$route.params['couponfamily_id'];
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getAdmin",get_admin_body)
      .then((adminReadSuccess) =>{
        this.currentUserName = adminReadSuccess.data.data.admin_name;
        this.currentUserId = adminReadSuccess.data.data._id;
        var coupon_master_body =  {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          'couponMasterId' : this.coupon_id
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/readParticularCouponFamily", coupon_master_body)
        .then((couponMasterResponse) =>{
          console.log(couponMasterResponse);
          if(couponMasterResponse.data.data.length > 0)
          {
            this.master_coupon_details = couponMasterResponse.data.data[0];
            this.coupon_name = this.master_coupon_details.master_coupon_code;
            this.coupon_start_date = this.master_coupon_details.valid_from;
            this.coupon_end_date = this.master_coupon_details.valid_till;
            this.total_published = this.master_coupon_details.coupon_quantity;
            this.used_count = this.master_coupon_details.used_coupons_object.length;
            this.allUsedCoupons = this.master_coupon_details.used_coupons_object;
            this.unused_count = this.total_published - this.used_count;
            this.coupon_desc = this.master_coupon_details.coupon_description;
            this.applicable_on = this.master_coupon_details.applicable_on.join(",");
            this.applicable_to = this.master_coupon_details.applicable_to.join(",");
            this.coupon_type = this.master_coupon_details.coupon_type;
            this.max_discount_amount = this.master_coupon_details.max_discount_amount;
            this.minimum_spend_amount = this.master_coupon_details.min_amount_spend;
            this.discounted_value = this.master_coupon_details.discount;
            // this.applicable_list = this.master_coupon_details.applicable_list;
            var doctorNames = this.master_coupon_details.DoctorInfo.map((x) => {return x.doctor_name});
            var specNames = this.master_coupon_details.specInfo.map((x) =>{ return x.specialization_name});
            doctorNames.concat(specNames);
            this.applicable_list = doctorNames.concat(specNames);
          }
          this.isLoading = false;
        })
        .catch((CouponMasterError) =>{
          this.isLoading = false;
          window.alert("Error: ", CouponMasterError);
          console.log(CouponMasterError);
        });
        var data = {
          token : this.currentUser,
          typeOfUser : "ADMIN",
          'couponMasterId': this.coupon_id
        };
      })
      .catch((adminReadError) =>{
        this.isLoading = false;
        window.alert("Error: ", adminReadError);
        console.log(adminReadError);
      });
    }
  },
  methods: {
    toggleDetails()
    {
      this.view_more = !this.view_more;
      if(this.view_more)
      {
        this.toggle_text = "Show Less";
        this.showContentClass = "ShowLessClass";
      }
      else
      {
        this.toggle_text = "Show More"
        this.showContentClass = "ShowMoreClass";
      }
    },
    search_validity()
    {
      this.search_disable = true;
      let coupon_code = this.search_coupon_availability;
      var coupon_body = {
        couponCode: coupon_code,
        token: this.currentUser,
        typeOfUser: "ADMIN"
      }

      axios.post(process.env.VUE_APP_BACKEND_URL+"/readCouponByCode", coupon_body)
      .then((couponResponse) => {
        if(couponResponse.data.data)
          window.alert(couponResponse.data.data.availability);
        else
          window.alert("No such coupon found");
        this.search_disable = false;
      })
      .catch((couponError) =>{
        console.log(couponError)
        this.search_disable = false;
        if (couponError.status == 404) {
          window.alert ("No such Coupon Exists");
        } else {
          window.alert("Error while getting coupon availability");
        }
      });
    }
  },
};
</script>

<style scoped>
.textClass {
  font-size: 8pt;
}
.ShowMoreClass
{
  transform: rotateX(180deg);

}
.ShowLessClass
{
  image-orientation:  from-image;
}
.viewMoreclass {
  background-color: #E4F5FF;
  border-radius: 16px 16px 0px 0px;
  padding: 4px 10px;
  width: 15%;
  color: #1467BF;
  display: flex; 
  flex-direction: row;
  justify-content: center;
}
.coupon_desc_class
{
  /*border-style: none none groove none; padding: 16px;*/
  padding: 4px;
  width: 33%;
}
.coupon_details_title
{
  font-size: 8pt;
  line-height: 24px;
  color: #828282;
  padding: 4px;
}
.coupon_details_value
{
  font-size: 8pt;
  line-height: 24px;
  color: #333333;
  padding: 4px;
  font-weight: 600;
}
.fixTableHead {
  overflow-y: auto;
  height: 50vh;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;        
  width: 100%;
}
td,
th {
  border: 0px;
}
td {
  padding: 2px;
  font-size: 8pt;
  font-weight: 600;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 600;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 0;
  height: 40px;
}
tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
}
table > tr > td:last-child {
    white-space: nowrap;
}
</style>